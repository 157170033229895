<template>
  <!-- 第三方订单列表页面 -->
  <div class="padding24 color_white" id="third_Party_order">
    <a-spin :spinning="spinning" size="large" :tip="tip">
      <div>
        <div class="top">
          <a-form layout="inline">
            <a-form-item label="第三方订单编号">
              <a-input
                v-model="orderNo"
                placeholder="请输入订单编号"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <a-form-item label="用户手机号">
              <a-input
                v-model="phone"
                placeholder="请输入用户手机号"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <a-form-item label="商品名称">
              <a-input
                v-model="businessName"
                placeholder="请输入商品名称"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <a-form-item label="抖店商品编码">
              <a-input
                v-model="trillCommodityCode"
                placeholder="请输入抖店商品编码"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <a-form-item label="商家编码">
              <a-input
                v-model="commodityCode"
                placeholder="请输入商家编码"
                @pressEnter="searchClick"
              ></a-input>
            </a-form-item>
            <!-- </a-form>
          <div class="top_line"> -->
            <a-form-item label="商品类型">
              <a-select
                v-model="orderType"
                :getPopupContainer="
                  (triggerNode) => triggerNode.parentNode || document.body
                "
                style="width: 100px"
                @change="changeOrderType"
              >
                <a-select-option v-for="item in orderTypeArr" :key="item.key">
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="订单状态">
              <a-select
                v-model="tiktokShopOrderStatus"
                :getPopupContainer="
                  (triggerNode) => triggerNode.parentNode || document.body
                "
                style="width: 100px"
                @change="changeTiktokShopOrderStatus"
              >
                <a-select-option
                  v-for="item in tiktokShopOrderStatusArr"
                  :key="item.key"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="支付方式">
              <a-select
                v-model="tiktokShopPayType"
                :getPopupContainer="
                  (triggerNode) => triggerNode.parentNode || document.body
                "
                style="width: 100px"
                @change="changeTiktokShopPayType"
              >
                <a-select-option
                  v-for="item in tiktokShopPayTypeArr"
                  :key="item.key"
                >
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="领取方式">
              <a-select
                v-model="receive"
                :getPopupContainer="
                  (triggerNode) => triggerNode.parentNode || document.body
                "
                @change="changeReceive"
              >
                <a-select-option v-for="item in receiveArr" :key="item.key">
                  {{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="下单时间">
              <a-range-picker
                :value="rangePicker"
                show-time
                format="YYYY-MM-DD HH:mm:ss"
                @change="onChange"
              />
            </a-form-item>
            <a-form-item>
              <a-button
                icon="search"
                type="primary"
                @click="searchClick"
                :disabled="isDisableSearch"
              >
                搜索
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button icon="redo" type="primary" @click="resetClick">
                重置
              </a-button>
            </a-form-item>
          </a-form>
          <a-form layout="inline">
            <a-form-item>
              <a-button
                v-if="isShowOrderSync"
                icon="ordered-list"
                type="primary"
                @click="synchronousClick"
              >
                同步第三方订单
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button
                v-if="isShowOrderExport"
                icon="export"
                type="primary"
                @click="exportClick"
              >
                导出订单
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <!-- 表格 -->
        <a-table
          :columns="columns"
          :data-source="tableData"
          :pagination="false"
          :loading="tableLoading"
          :scroll="{ x: 2500, y: 520 }"
        >
          <span slot="payType" slot-scope="payType, record">
            <span v-if="record.payType == 0">货到付款</span>
            <span v-if="record.payType == 1">微信</span>
            <span v-if="record.payType == 2">支付宝</span>
            <span v-if="record.payType == 3">小程序</span>
            <span v-if="record.payType == 4">银行卡</span>
            <span v-if="record.payType == 5">余额</span>
            <span v-if="record.payType == 7">免费</span>
            <span v-if="record.payType == 8">DOU分期</span>
            <span v-if="record.payType == 9">新卡支付</span>
          </span>
          <span slot="orderStatus" slot-scope="orderStatus, record">
            <span v-if="record.orderStatus == 1">待确认</span>
            <span v-if="record.orderStatus == 105">已支付</span>
            <span v-if="record.orderStatus == 2">备货中</span>
            <span v-if="record.orderStatus == 101">部分发货</span>
            <span v-if="record.orderStatus == 3">已发货</span>
            <span v-if="record.orderStatus == 4">已取消</span>
            <span v-if="record.orderStatus == 5">已完成</span>
          </span>
          <span slot="receiveStatus" slot-scope="receiveStatus, record">
            <span v-if="record.receiveStatus == 0">未领取</span>
            <span v-if="record.receiveStatus == 1">已领取</span>
          </span>
          <span slot="businessName" slot-scope="businessName, record">
            <span v-if="record.courseName != ''">{{ record.courseName }}</span>
            <span v-if="record.memberName != ''">{{ record.memberName }}</span>
            <span v-if="record.memberName == '' && record.courseName == ''"
              >——</span
            >
          </span>
          <span slot="businessType" slot-scope="businessType, record">
            <span v-if="record.courseName != ''">课程</span>
            <span v-if="record.memberName != ''">会员</span>
            <span v-if="record.memberName == '' && record.courseName == ''"
              >——</span
            >
          </span>
          <span slot="operation" slot-scope="operation, record">
            <a
              id="copy_memberNo"
              type="primary"
              class="margin_right20"
              @click="appShow(record.orderNo)"
            >
              修改手机号
            </a>
          </span>
        </a-table>
        <a-modal
          title="修改手机号"
          :width="640"
          :visible="visible"
          :confirmLoading="confirmLoading"
          okText="确定"
          cancelText="取消"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{ span: 7 }"
            :wrapperCol="{ span: 13 }"
          >
            <a-form-model-item label="手机号码" prop="labelPhone">
              <a-input
                v-model="form.labelPhone"
                placeholder="请输入手机号码"
              ></a-input>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
        <MyPagination
          :count="count"
          :pageNo="pageNo"
          @showSizeChangeFn="showSizeChangeFn"
          v-show="!tableLoading"
        />
        <!-- 返回顶部 -->
        <a-back-top :target="targetFn" :visibilityHeight="100" />
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import {
  GetThreeOrdersApi,
  OrderSyncApi,
  OrderSyncExportApi,
  UpOrderPhoneApi,
} from "@/request/api/orderformManage";
import MyPagination from "@/components/pagination/MyPagination";
import { message } from "ant-design-vue";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    this.GetThreeOrdersFn();
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.orderType.forEach((item) => this.orderTypeArr.push(item));
    dictionaryObj.tiktokShopOrderStatus.forEach((item) =>
      this.tiktokShopOrderStatusArr.push(item)
    );
    dictionaryObj.tiktokShopPayType.forEach((item) =>
      this.tiktokShopPayTypeArr.push(item)
    )
    if (codeFn("/admin/order/sync")) this.isShowOrderSync = true;
    if (codeFn("/admin/order/sync/export")) this.isShowOrderExport = true;
  },
  data() {
    return {
      spinning: false, // 全局loading
      tip: '',
      pageNo: 1,
      pageSize: 20,
      count: 0,
      orderNo: '',
      phone: '',
      businessName: '',
      trillCommodityCode: '',
      commodityCode: '',
      orderType: 0, // 商品类型
      orderTypeArr: [{ key: 0, value: '全部类型' }],
      tiktokShopOrderStatus: 0, // 订单状态的默认值
      tiktokShopOrderStatusArr: [{ key: 0, value: '全部状态' }], //订单状态的数组
      tiktokShopPayType: -1, // 支付方式默认值
      tiktokShopPayTypeArr: [{ key: -1, value: '全部状态' }], // 支付方式的数组
      receive: "0", // 领取方式
      receiveArr: [
        { key: '', value: "全部" },
        { key: "0", value: "未领取" },
        { key: "1", value: "已领取" },
      ],
      rangePicker: [], // 下单日期数组
      startTime: '',
      endTime: '',
      isDisableSearch: false, // 搜索是否禁用
      tableLoading: true,
      columns: [
        {
          title: "第三方订单编号",
          dataIndex: "orderNo",
          key: "orderNo",
          width: "8%",
          scopedSlots: { customRender: "orderNoorderNo" },
        },
        {
          title: "商品名称",
          dataIndex: "businessName",
          key: "businessName",
          width: "15%",
          scopedSlots: { customRender: "businessName" },
        },
        {
          title: "商品类型",
          dataIndex: "businessType",
          key: "businessType",
          width: "4%",
          scopedSlots: { customRender: "businessType" },
        },
        {
          title: "抖店商品编码",
          dataIndex: "trillCommodityCode",
          key: "trillCommodityCode",
          width: "8%",
        },
        {
          title: "商家编码",
          dataIndex: "commodityCode",
          key: "commodityCode",
          width: "8%",
        },
        {
          title: "抖店商品Sku编码",
          dataIndex: "skuId",
          key: "skuId",
          width: "8%",
        },
        {
          title: "支付金额",
          dataIndex: "price",
          key: "price",
          width: "4%",
        },
        {
          title: "优惠总金额",
          dataIndex: "promotionAmount",
          key: "promotionAmount",
          width: "5%",
        },
        {
          title: "用户手机号",
          dataIndex: "phone",
          key: "phone",
          width: "6%",
        },
        {
          title: "收件人",
          dataIndex: "userName",
          key: "userName",
          width: "4%",
        },
        {
          title: "支付类型",
          dataIndex: "payType",
          key: "payType",
          width: "4%",
          scopedSlots: { customRender: "payType" },
        },
        {
          title: "订单状态",
          dataIndex: "orderStatus",
          key: "orderStatus",
          width: "4%",
          scopedSlots: { customRender: "orderStatus" },
        },
        {
          title: "领取状态",
          dataIndex: "receiveStatus",
          key: "receiveStatus",
          width: "4%",
          scopedSlots: { customRender: "receiveStatus" },
        },
        {
          title: "下单时间",
          dataIndex: "orderCreateTime",
          width: "5%",
          sorter: true,
          key: "orderCreateTime",
          sorter: (a, b) => {
            let aTime = new Date(a.orderCreateTime).getTime();
            let bTime = new Date(b.orderCreateTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "支付时间",
          dataIndex: "payTime",
          width: "5%",
          sorter: true,
          key: "payTime",
          sorter: (a, b) => {
            let aTime = new Date(a.payTime).getTime();
            let bTime = new Date(b.payTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "订单完成时间",
          dataIndex: "finishTime",
          width: "6%",
          sorter: true,
          key: "finishTime",
          sorter: (a, b) => {
            let aTime = new Date(a.finishTime).getTime();
            let bTime = new Date(b.finishTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "推广类型",
          dataIndex: "sendPay",
          key: "receiveStatussendPay",
          //   width: "13%",
          scopedSlots: { customRender: "sendPay" },
        },
        {
          title: "操作",
           fixed: "right",
           width:130,
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      isShowOrderSync: false, // 同步订单是否隐藏
      isShowOrderExport: false, // 同步订单是否隐藏
      visible: false,
      form: {
        labelPhone: '',
      },
      rules: {
        labelPhone: [
          { required: true, validator: this.validatePhone, trigger: "blur" },
        ],
      },
      confirmLoading: false,
    };
  },
  methods: {
    moment,
    // 标签提交
    handleOk() {
      this.confirmLoading = true;
      UpOrderPhoneApi({
        types: "1",
        orderNo: this.orderNoNew,
        phone: this.form.labelPhone,
      }).then((res) => {
        if (res.code === 200) {
          this.visible = false;
          this.confirmLoading = false;
          this.$refs.form.resetFields(); // 重置表格
          message.success("修改成功");
          this.GetThreeOrdersFn();
        } else {
          setTimeout(() => {
            this.confirmLoading = false;
          }, 2000);
        }
      });
    },
    // 标签取消或关闭
    handleCancel() {
      this.visible = false;
      this.$refs.form.resetFields(); // 重置表格
    },
    // 手机号校验规则
    validatePhone(rule, value, callback) {
      let reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback("请输入正确的手机号");
      } else {
        callback();
      }
    },
    appShow(no) {
      this.orderNoNew = no;
      this.visible = true;
    },
    // 商品类型改变的回调
    changeOrderType(value) {
      this.pageNo = 1;
      this.orderType = value;
      this.GetThreeOrdersFn();
    },
    // 订单状态改变的回调
    changeTiktokShopOrderStatus(value) {
      this.pageNo = 1;
      this.tiktokShopOrderStatus = value;
      this.GetThreeOrdersFn();
    },
    // 支付方式改变的回调
    changeTiktokShopPayType(value) {
      this.pageNo = 1;
      this.tiktokShopPayType = value
      this.GetThreeOrdersFn();
    },
    // 领取方式改变的回调
    changeReceive(value) {
      this.pageNo = 1;
      this.receive = value;
      this.GetThreeOrdersFn();
    },
    // 下单日期改变的回调
    onChange(date, dateString) {
      if (dateString[0] != "") {
        this.startTime = dateString[0];
        this.endTime = dateString[1];
        this.rangePicker = [
          moment(dateString[0], "YYYY-MM-DD HH:mm:ss"),
          moment(dateString[1], "YYYY-MM-DD HH:mm:ss"),
        ];
      } else {
        this.startTime = "";
        this.endTime = "";
        this.rangePicker = [];
      }
    },
    // 搜索的按钮
    searchClick() {
      this.pageNo = 1;
      this.isDisableSearch = true;
      setTimeout(() => {
        this.isDisableSearch = false;
      }, 3000);
      this.GetThreeOrdersFn();
    },
    // 重置按钮
    resetClick() {
      this.orderNo = "";
      this.phone = "";
      this.businessName = "";
      this.orderType = this.$options.data().orderType
      this.tiktokShopOrderStatus = this.$options.data().tiktokShopOrderStatus
      this.trillCommodityCode = "";
      this.commodityCode = "";
      this.tiktokShopPayType = this.$options.data().tiktokShopPayType
      this.receive = "";
      this.rangePicker = [];
      this.startTime = "";
      this.endTime = "";
    },
    // 同步第三方订单按钮
    synchronousClick() {
      let date1 = new Date("2021-07-22 18:00:00:000");
      let time1 = Date.parse(date1);
      let date2 = new Date(this.startTime);
      let time2 = Date.parse(date2);
      this.tip = "同步第三方订单中";
      this.spinning = true;
      if (this.startTime == "" || this.endTime == "") {
        message.error("请选择下单时间");
        this.spinning = false;
      } else if (time1 > time2) {
        message.error("请选择同步七月二十二号六点之后的订单");
        this.spinning = false;
      } else {
        OrderSyncApi({
          endTime: this.endTime,
          startTime: this.startTime,
        }).then(({ code, data }) => {
          if (code == 200) {
            message.success("同步第三方订单成功");
            this.GetThreeOrdersFn();
            this.spinning = false;
          } else {
            this.spinning = false;
          }
        });
      }
    },
    // 导出订单按钮
    async exportClick() {
      this.tip = '订单导出中'
      this.spinning = true
      const params = {
        orderNo: this.orderNo, // 订单编号
        phone: this.phone, // 手机号
        businessName: this.businessName, // 商品名称
        trillCommodityCode: this.trillCommodityCode, // 抖店商品编码
        commodityCode: this.commodityCode, // 商品编码
        businessType: this.orderType, // 商品类型
        orderStatus: this.tiktokShopOrderStatus, // 订单状态
        payType: this.tiktokShopPayType, // 支付方式
        receiveStatus: this.receive, // 是否领取
        startTime: this.startTime,
        endTime: this.endTime,
      }
      const { code, data } = await OrderSyncExportApi(params)
      this.spinning = false
      if (code !== 200) return
      console.log(data)
      window.location.href = data
      this.spinning = false
      message.success('导出订单成功')
    },
    // 分页功能切换的回调
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current
      this.pageSize = pageSize
      this.GetThreeOrdersFn()
    },
    // 获取第三方订单列表
    async GetThreeOrdersFn() {
      this.tableLoading = true
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        orderNo: this.orderNo, // 订单编号
        phone: this.phone, // 手机号
        businessName: this.businessName, // 商品名称
        trillCommodityCode: this.trillCommodityCode, // 抖店商品编码
        commodityCode: this.commodityCode, // 商品编码
        businessType: this.orderType, // 商品类型
        orderStatus: this.tiktokShopOrderStatus, // 订单状态
        payType: this.tiktokShopPayType, // 支付方式
        receiveStatus: this.receive, // 是否领取
        startTime: this.startTime,
        endTime: this.endTime,
      }
      const { code, data } = await GetThreeOrdersApi(params)
      this.tableLoading = false
      this.tableData = []
      if (code !== 200 || data.dataInfo === null) return
      this.count = data.count;
      data.dataInfo.forEach((item) => {
        item.key = item.orderNo;
        item.userName = item.userName === '' ? '——' : item.userName
        item.payTime = item.payTime === null ? '——' : item.payTime
        item.finishTime = item.finishTime === null ? '——' : item.finishTime
      });
      this.tableData = data.dataInfo
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#third_Party_order");
    },
  },
};
</script>
 
<style lang="less" scoped>
#third_Party_order {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.top {
  .top_line {
    margin-bottom: 20px;
    display: flex;
    span {
      margin-right: 20px;
      /deep/.ant-input {
        width: 220px;
      }
      /deep/.ant-select-selection {
        width: 110px;
        margin-left: 10px;
      }
      /deep/.ant-calendar-picker-input {
        margin-left: 10px;
        width: auto;
      }
      /deep/.ant-calendar-picker {
        width: 400px !important;
      }
    }
    .ant-btn {
      margin-right: 20px;
    }
  }
}
</style>